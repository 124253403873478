<template>
  <el-container id="home-container">
    <el-header>
      <div>
        <i
          :class="isCollapsed ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
          class="toggle-button"
          @click="toggleCollapse"
        ></i>
        <span>TankManager</span>
      </div>
      <div class="right-menu">
        <el-dropdown
          style="
            color: #fff;
            display: flex;
            align-items: center;
            margin-left: 15px;
          "
          @command="handleCommand"
        >
          <span
            class="el-dropdown-link"
            style="height: 30px; line-height: 30px"
          >
            {{ userData.nickName }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="modifyPassword">修改密码</el-dropdown-item>
            <el-dropdown-item command="logOut">注销</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </el-header>
    <el-container>
      <el-aside :width="isCollapsed ? '64px' : '200px'">
        <el-menu
          :collapse="isCollapsed"
          unique-opened
          router
          :default-active="activePath"
          :collapse-transition="false"
        >
          <el-submenu index="1">
            <template slot="title">
              <i class="el-icon-user"></i>
              <span>App系统管理</span>
            </template>
            <el-menu-item
              v-for="(item, index) in appRoutes"
              :index="item.path"
              :key="index"
              @click="handleClick(item.path)"
            >
              <template slot="title">
                <span>{{ item.meta.title }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <i class="el-icon-setting"></i>
              <span>Cms系统管理</span>
            </template>
            <el-menu-item
              v-for="(item, index) in cmsRoutes"
              :index="item.path"
              :key="index"
              @click="handleClick(item.path)"
            >
              <template slot="title">
                <span>{{ item.meta.title }}</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <router-view></router-view>
    </el-container>
    <ele-form-dialog
      v-model="formData"
      :form-desc="formDesc"
      title="修改密码"
      :request-fn="handleSubmit"
      :visible.sync="dialogVisible"
      @closed="handleClosed"
      ></ele-form-dialog>
  </el-container>
</template>

<script>
import { systemRoutes } from "../assets/js/routes";
export default {
  data() {
    var confirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.formData.new) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      isCollapsed: false,
      userData: {},
      activePath: "",
      appRoutes: [],
      cmsRoutes: [],
      dialogVisible: false,
      formDesc: {
        origin: {
          label: '原密码',
          type: 'password',
          required: true
        },
        new: {
          label: '新密码',
          type: 'password',
          required: true
        },
        confirm: {
          label: '确认密码',
          type: 'password',
          required: true,
          rules: [
            { required: true, validator: confirmPassword, trigger: 'blur' }
          ]
        }
      },
      formData: {}
    };
  },
  created() {
    this.activePath = this.$store.state.activePath;
    this.userData = this.$store.state.userData;
    this.appRoutes = systemRoutes.filter((item) => item.meta.type === "app");
    this.cmsRoutes = systemRoutes.filter((item) => item.meta.type === "cms");
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    handleCommand(command) {
      if (command === "logOut") {
        this.$store.commit("logout");
        this.$router.push("/login");
      } else if (command == 'modifyPassword') {
        this.dialogVisible = true
      }
    },
    async handleSubmit(data) {
      data.userId = this.$store.state.userData.id
      const { data: res } = await this.$api.modifyPassword(data)
      if (res.code > 0) {
        return this.$notify.error({
          title: '错误',
          message: res.message
        })
      }
      this.$notify.success({
        title: '成功',
        message: res.message
      })
      this.$store.commit("logout")
    },
    handleClosed() {
      this.formData = {}
    },
    handleClick(data) {
      this.$store.commit("setActivePath", data);
    },
  },
};
</script>

<style scoped>
#home-container {
  height: 100vh;
}
.el-header {
  background-color: #409eff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 20px;
}
.toggle-button {
  margin-right: 5px;
}

.el-aside {
  background-color: #fff;
}

.el-menu {
  height: calc(100vh - 60px);
}
</style>
